import { $ } from '../vendors/jquery';

class FilterOrderNow {

    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        let filterDevices = {};

        $(this.element).find('[data-filter-name="text"]').on('change paste input', (e) => {
            let deviceName = e.target.value.trim();
            filterDevices.name = deviceName.toUpperCase();
            this.filter(filterDevices);
        });

        $(this.element).find('[data-filter-type="select"]').on('change', (e) => {
            let dataName = e.target.getAttribute('data-filter-name');
            let dataValue = e.target.value;
            filterDevices[dataName] = dataValue;
            this.filter(filterDevices);
        });

        $(this.element).find('[data-filter-type="checkbox"]').on('change', (e) => {
            let dataName = e.target.getAttribute('data-filter-name');
            let isChecked = e.target.checked;
        
            if (isChecked) {
            
                if (!filterDevices[dataName]) {
                    filterDevices[dataName] = [];
                }

                filterDevices[dataName].push(e.target.value);
            } else {

                if (filterDevices[dataName]) {
                    let index = filterDevices[dataName].indexOf(e.target.value);
                    
                    if (index !== -1) {
                        filterDevices[dataName].splice(index, 1);
                    }

                    if (filterDevices[dataName].length === 0) {
                        delete filterDevices[dataName];
                    }
                }
            }

            this.filter(filterDevices);
        });

        $(this.element).find('[data-filter-type="radio"]').on('change', (e) => {
            let dataName = e.target.getAttribute('data-filter-name');
            let dataValue = e.target.value;
            filterDevices[dataName] = dataValue;
            this.filter(filterDevices);
        });

        $(this.element).find('[data-filter-reset').on('click', (e) => {
            filterDevices = {};
            $(this.element).find('[data-filter-name="text"]').val('');
            $(this.element).find('[data-filter-type="select"]').prop('selectedIndex', 0);
            $(this.element).find('[data-filter-type="checkbox"]').removeAttr('checked');
            $(this.element).find('[data-filter-type="radio"]').prop('checked', false);
            this.filter(filterDevices);
        });
    }

    filter(filterDevices) {

        if (filterDevices && Object.keys(filterDevices).length != 0) {
            $('.reset_filters').removeClass('d-none');
        } else {
            $('.reset_filters').addClass('d-none');
        }

        $('[data-filter-body="order-now"] tbody tr').each(function () {
            const deviceName = $(this).find('[data-filter-name="partnumber"]').text().trim().toUpperCase();
            const deviceA32 = $(this).find('[data-filter-name="A32"]').text().trim();
            const deviceM55 = $(this).find('[data-filter-name="M55"]').text().trim();
            const deviceNPU = $(this).find('[data-filter-name="NPU"]').text().trim();
            const deviceGPU = $(this).find('[data-filter-name="gputype"]').text().trim();
            const deviceMRAM = parseFloat($(this).find('[data-filter-name="mramsizemb"]').text().trim());
            const deviceSDRAM = parseFloat($(this).find('[data-filter-name="sramsizemb"]').text().trim());
            const deviceCANFD = $(this).find('[data-filter-name="serialchanntype"]').text().trim();
            const deviceDisplayType = $(this).find('[data-filter-name="displaytype"]').text().trim();
            const deviceCameraType = $(this).find('[data-filter-name="cameratype"]').text().trim();
            const devicePackageType = $(this).find('[data-filter-name="packagetype"]').text().trim();

            if (
                (typeof filterDevices.name == 'undefined' || !filterDevices.name || deviceName.includes(filterDevices.name)) &&
                (typeof filterDevices.series == 'undefined' || !filterDevices.series || filterDevices.series === 'All' || deviceName.includes(filterDevices.series.toUpperCase())) &&
                (typeof filterDevices.A32 == 'undefined' || !filterDevices.A32 || filterDevices.A32 === 'All' || deviceA32 === filterDevices.A32) &&
                (typeof filterDevices.M55 == 'undefined' || !filterDevices.M55 || filterDevices.M55 === 'All' || deviceM55 === filterDevices.M55) &&
                (typeof filterDevices.NPU == 'undefined' || !filterDevices.NPU || filterDevices.NPU === 'All' || deviceNPU === filterDevices.NPU) &&
                (typeof filterDevices.mramsizemb == 'undefined' || !filterDevices.mramsizemb || (deviceMRAM === parseFloat(filterDevices.mramsizemb))) &&
                (typeof filterDevices.sramsizemb == 'undefined' || !filterDevices.sramsizemb || (deviceSDRAM === parseFloat(filterDevices.sramsizemb))) &&
                (typeof filterDevices.gputype == 'undefined' || !filterDevices.gputype || filterDevices.gputype.length === 0 || filterDevices.gputype.every(gpu => deviceGPU.includes(gpu))) &&
                (typeof filterDevices.serialchanntype == 'undefined' || !filterDevices.serialchanntype || filterDevices.serialchanntype.length === 0 || filterDevices.serialchanntype.every(serialchanntype => deviceCANFD.includes(serialchanntype))) &&
                (typeof filterDevices.displaytype == 'undefined' || !filterDevices.displaytype || filterDevices.displaytype.length === 0 || filterDevices.displaytype.every(displaytype => deviceDisplayType.includes(displaytype))) &&
                (typeof filterDevices.cameratype == 'undefined' || !filterDevices.cameratype || deviceCameraType === filterDevices.cameratype) &&
                (typeof filterDevices.packagetype == 'undefined' || !filterDevices.packagetype || devicePackageType === filterDevices.packagetype)
            ) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    }
}

const filterordernowjs = {
    init() {
        const exampleElements = $('[data-filter-header="order-now"]');

        if (null !== exampleElements && exampleElements.length > 0) {
            exampleElements.each((index, element) => {
                new FilterOrderNow($(element));
            });
        }

    },
};

export default filterordernowjs;
import { $ } from '../vendors/jquery';

const BlogLoadMore = {
    button: $('[data-blog-grid="load_more"]').find('button'),
    blogFilter: $('.blog_search'),
    blogFilterResults: $('.blog_posts_grid .blog-list'),
    loadMoreWrapper: $('.blog_posts_grid .load-more-wrapper'),

    loadMore() {
        let page = '';

        if (BlogLoadMore.blogFilter.attr('data-page')) {
            page = BlogLoadMore.blogFilter.attr('data-page');
        } else {
            page = BlogLoadMore.blogFilterResults.attr('data-page');
        }

        let tags = BlogLoadMore.blogFilter.attr('data-tags');
        let search = BlogLoadMore.blogFilter.attr('data-search');
        let category = BlogLoadMore.blogFilter.attr('data-category');
        let per_page = parseInt(BlogLoadMore.blogFilterResults.attr('data-per-page'));
        let post_type = BlogLoadMore.blogFilterResults.attr('data-post_type');

        page = parseInt(page) + 1;        
        BlogLoadMore.blogFilter.attr('data-page', page);

        const data = {
            page: page,
            action: "alif_blog_loadmore",
            per_page: per_page,
            post_type: post_type,
        };

        if (tags) {
            data.tags = tags;
        }

        if (category) {
            data.category = category;
        } else {
            data.category = 'all';
        }

        if (search) {
            data.search = search;
        }

        $.ajax({
            url: alifteam.ajax.url,
            type: 'POST',
            data: data,
            cache: false,
            beforeSend: () => {
                BlogLoadMore.button.show();
                BlogLoadMore.loadMoreWrapper.addClass('loading');
                BlogLoadMore.loadMoreWrapper.removeClass('no-results hidden');
            },
            success: (response) => {

                //hide button if no results
                if (!response.html) {
                    BlogLoadMore.loadMoreWrapper.addClass('no-results');
                }

                //hide button if no more posts
                if (response.hideButton) {
                    BlogLoadMore.button.hide();
                }

                //append after last post
                BlogLoadMore.blogFilterResults.append(response.html);
                BlogLoadMore.loadMoreWrapper.removeClass('loading');
            },
            error: (error) => {
                console.log(error);
                BlogLoadMore.loadMoreWrapper.removeClass('loading');
            }
        });
    },

    clearList() {
        BlogLoadMore.blogFilter.attr('data-page', 0);
        BlogLoadMore.blogFilterResults.html('');
    },

    init() {
        BlogLoadMore.button.on('click', () => {
            BlogLoadMore.loadMore();
        });
    }
};

export default BlogLoadMore;

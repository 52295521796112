import { $ } from '../vendors/jquery';

class Accordion
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
        this.element.click(function (e) {
            $(this).find('.faq_arrows div').toggleClass('active');
            $(this).next().slideToggle();
        });
    }

    static locationsInit( elem )
    {
        elem.click(function(e) {
            $(this).find('.location_arrows div').toggleClass('active');
            $(this).find('.location_details').slideToggle();
        });
    }
}

const accordionjs = {
    init() {
        const exampleElements = $('.faq_question_answer .faq_question')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new Accordion( $( element ) );
            } );
        }
    },
    locationsInit() {
        const exampleElements = $('.sales_support_locations .locations')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                Accordion.locationsInit( $( element ) );
            } );
        }
    }
};

export default accordionjs;

import { $ } from '../vendors/jquery';

class MasonryJS
{
    constructor( element )
    {
        this.element = element;
        this.init();
        this.msnry = '';
    }

    init()
    {
        let $msnryalifnews = '';

        /*
            if( this.element.hasClass('alifinthenews') ) {
                $msnryalifnews = $( this.element[0] ).imagesLoaded( function() {
                    $msnryalifnews.masonry({
                        columnWidth: '.post-sizer',
                        itemSelector: '.post',
                        percentPosition: true,
                        gutter: 25,
                        fitWidth: true
                    });
                });
            }
        */

        if( this.element.hasClass('meetourteam') ) {
            const $msnryteam = $( this.element[0] ).imagesLoaded( function() {
            // init Masonry after all images have loaded
            $msnryteam.masonry({
                columnWidth: '.teammembers-sizer',
                itemSelector: '.teammembers',
                percentPosition: true,
                fitWidth: true,
                gutter: 0,
            });
            });
        }

        $('.pagination_loadmore').on('click', (e) => {
            let data = {
                action: 'alif_pagination_loadmore',
                //category: e.target.getAttribute('data-loadmore_category'),
                post_type: e.target.getAttribute('data-loadmore_post_type'),
                load_initial: e.target.getAttribute('data-loadmore_initial'),
                page: e.target.getAttribute('data-loadmore_page'),
                load_page: e.target.getAttribute('data-loadmore_load_page'),
            };
            console.log(data);
            $.post(alifteam.ajax.url, data, (response) => {
                //$msnryalifnews.append( $(response.html) ).masonry( 'appended', $(response.html) ).masonry('reloadItems').masonry( this.element );
                //console.log(response.html);
                //console.log(response.hideButton);

                $(response.html).insertBefore('.loadmore');
            
                if( response.hideButton != true ) {
                    let dataLoadInitial = e.target.getAttribute('data-loadmore_initial');
                    let dataPage = e.target.getAttribute('data-loadmore_page');
                    dataLoadInitial = parseInt(dataLoadInitial)+1;
                    dataPage = parseInt(dataPage)+1;
                    $('.loadmore .pagination_loadmore').attr('data-loadmore_initial',dataLoadInitial);
                    $('.loadmore .pagination_loadmore').attr('data-loadmore_page',dataPage);
                } else {
                    $('.loadmore').remove();
                }

            });
        });
    }

}

const masonryjs = {
    init() {
        const exampleElements = $( '.masonry' );

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new MasonryJS( $( element ) );
            } );
        }
    }
};

export default masonryjs;

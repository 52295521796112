import { $ } from '../vendors/jquery';

class SwiperJS
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      const alifCarousel = this.element[0];
      const alifCarouselSwiper = this.element.find('.swiper');
      const alifCarouselNavigation = this.element.find('.swiper_navigation');

      if( alifCarouselSwiper.hasClass('partnersquote') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });
      }
      if( alifCarouselSwiper.hasClass('partnersquotes_partners') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
        });
      }
      if( alifCarouselSwiper.hasClass('pressreleases') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          //centeredSlides: true,
          slidesPerView: 1,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });
      }
      if( alifCarouselSwiper.hasClass('solution') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          autoHeight: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          pagination: {
            el: alifCarouselNavigation.find('.swiper-pagination')[0],
            type: 'bullets',
            clickable: true,
          },
          slidesPerView: 1,
          spaceBetween: 25,
        });
      }
      if( alifCarouselSwiper.hasClass('support_documents') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 4.5,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
            1200: {
              slidesPerView: 4.5,
            },
          },
        });
      }
      if( alifCarouselSwiper.hasClass('news') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
        });
      }
      if( alifCarouselSwiper.hasClass('allsolutions_icons') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          },
        });
      }
      if (alifCarouselSwiper.hasClass('partners_slide')) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          centeredSlides: true,
          autoplay: {
           delay: 1,
           disableOnInteraction: true,
          },
          freeMode: true,
          speed: 5000,
          freeModeMomentum: false,
          slidesPerView: 1,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 1.5,
            },
            1024: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3.5,
            },
          },
        });
      }
      if( alifCarouselSwiper.hasClass('teammembers') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          },
        });
      }
      if( alifCarouselSwiper.hasClass('meetyourexperts') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
        });
      }
      if( alifCarouselSwiper.hasClass('meetteam_slide') ) {
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 25,
        });
      }
      if (alifCarouselSwiper.hasClass('linkedin')) {
        console.log(alifCarouselSwiper[0]);
        // eslint-disable-next-line
        new Swiper(alifCarouselSwiper[0], {
          loop: true,
          navigation: {
            nextEl: alifCarouselNavigation.find('.button-next')[0],
            prevEl: alifCarouselNavigation.find('.button-prev')[0],
          },
          slidesPerView: 1,
          spaceBetween: 50,
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });
      }

    }
}

const swiperjs = {
    init() {
        const exampleElements = $( '[data-module="swiper"]' );

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new SwiperJS( $( element ) );
            } );
        }
    }
};

export default swiperjs;

import { $ } from '../vendors/jquery';

class SearchNavBar
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      this.element.click(function(e) {
        $('.search_nav__wrapper').toggleClass('active');
      });

      $(document).mouseup(function (e) {
        if ($(e.target).closest(".search_nav__wrapper.active .search_nav__content").length === 0) {
          $('.search_nav__wrapper').removeClass('active');
        }
      });
    }
}

const searchnavbarjs = {
    init() {
        const exampleElements = $('.search_nav')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new SearchNavBar( $( element ) );
            } );
        }
    }
};

export default searchnavbarjs;

import { $ } from '../vendors/jquery';

class FamilyFusion {
  constructor(element) {
    this.element = element;
    this.init();
  }


  init() {


    const hideBox = () => {
      $('.familyfusion_popup_wrapper').removeClass('col-12 col-md-6 col-lg-9 mb-4 active').addClass('d-none');
      $('.cardfamilyfusion_wrapper').show();
    };

    this.element.click(function (e) {
      $('.cardfamilyfusion_wrapper').removeClass('animate__animated');
      const popupClicked = $(this).data('familyfusion');
      $('.cardfamilyfusion_wrapper:not([data-familyfusion="' + popupClicked + '"])').hide();
      const familyfusion_popup = $('.familyfusion').find('.familyfusion_popup_wrapper[data-familyfusion-popup="' + popupClicked + '"');
      familyfusion_popup.removeClass('d-none').addClass('col-12 col-md-6 col-lg-9 mb-4 active');
    });

    $('.familyfusion_popup_wrapper .close').click(function () {
      hideBox();
    });

    $(document).mouseup(function (e) {
      if ($(e.target).closest(".familyfusion_popup_wrapper.active").length === 0) {
        hideBox();
      }
    });

    if (window.matchMedia("(max-width: 767px)").matches) {

      $(document).on('click', function (event) {
        // Check if the target element is not div B and is a descendant of div A
        if (!$(event.target).closest('.familyfusion_popup_wrapper__inside').length && $(event.target).closest('.familyfusion_popup_wrapper').length) {
          // The click happened outside div B but inside div A
          console.log("Click outside div B but inside div A.");
          hideBox();
        }
      });
    }

  }




}

const familyfusionjs = {
  init() {
    const exampleElements = $('[data-familyfusion]')

    if (null !== exampleElements && exampleElements.length > 0) {
      exampleElements.each((index, element) => {
        new FamilyFusion($(element));
      });
    }
  }
};

export default familyfusionjs;

import { $ } from '../vendors/jquery';

class SolutionsPopup
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      this.element.click(function(e) {
        const popupClicked = $(this).data('popup');
        $('.solutions_popups').removeClass('active');
        $('.solutions_popups').find('[data-popup]').removeClass('active');

        if( $('.solutions_popups [data-popup='+popupClicked+']').length ) {
          $('.solutions_popups').addClass('active');
          $('.solutions_popups').find('[data-popup='+popupClicked+']').addClass('active');
        }
      });

      $('.solutions_popups .close').click(function() {
        $('.solutions_popups').removeClass('active');
        $('.solutions_popups').find('[data-popup]').removeClass('active');
      });

      $(document).mouseup(function (e) {
        if ($(e.target).closest(".popup.active").length === 0) {
          $('.solutions_popups').removeClass('active');
          $('.solutions_popups').find('[data-popup]').removeClass('active');
        }
      });

    }
}

const solutionspopupjs = {
    init() {
        const exampleElements = $('svg [data-popup]')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new SolutionsPopup( $( element ) );
            } );
        }
    }
};

export default solutionspopupjs;

import { $ } from '../vendors/jquery';

class LayoutChanges
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      if( $('div#wpadminbar').length ) {
        $('.header').css('top', $( 'div#wpadminbar' ).height() + 'px' );
      }

      if( $('.header.position-absolute').length && $('.hero').hasClass('support_page') ) {
        $('.hero.support_page').css('padding-top', $( '.header.position-absolute' ).height() + 'px' );
      }

      if ($('body.page-id-999997087').length) {
        const headerHeight = $('.header').height();
        const viewPortHeight = $(window).height();
        const distributorPaddingTop = (headerHeight + (viewPortHeight * 0.1));
        $('.find_distributor').css('padding-top', distributorPaddingTop + 'px' );
      }

      $( '.alif_loginform #user_login' ).attr('placeholder', 'Username or Email Address');
      $( '.alif_loginform #user_pass' ).attr('placeholder', 'Password');

      let office_card_content = 0;
      $('.offices_card .office_card_content').each(function() {
        if( $(this).height() > office_card_content ) {
          office_card_content = $(this).height();
        }
        $(this).height(office_card_content);
      });
    }
}

const layoutchangesjs = {
    init() {
        const exampleElements = $('.site')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new LayoutChanges( $( element ) );
            } );
        }
    }
};

export default layoutchangesjs;

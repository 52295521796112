import { $ } from '../vendors/jquery';

class AnimeJS {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    $(window).on('scroll resize', this.checkIfInView.bind(this));
    $(window).trigger('scroll');
  }

  isElementInView(element, offset = 0) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const topInView = rect.top <= windowHeight - offset;
    return topInView;
  }

  checkIfInView() {
    const isInView = this.isElementInView(this.element[0], 200); // Checking with a 100px offset
    this.element.find('[data-animation-element]').each((index, element) => {
      $(element).addClass('animate__animated');
    });

    if (isInView) {
      this.element.find('[data-animation-element]').each((index, element) => {
        const animation = this.element.data('animation');
        $(element).addClass(animation).addClass('already__animated').css('animation-delay', `${index * 0.2}s`);
      });
    }
  }

}

const animejs = {
  init() {
    const exampleElements = $('[data-animation]')

    if (null !== exampleElements && exampleElements.length > 0) {
      exampleElements.each((index, element) => {
        new AnimeJS($(element));
      });
    }
  }
};

export default animejs;

import { $ } from '../vendors/jquery';

class HeaderJs {
    constructor(element) {
        this.element = element;
        this.init();

    }

    adjustMegamenuPosition(resize = false, firstRun = false) {

        //Reset visibliy and display to detect offset
        if (resize) {
            let allMainItems = this.element.find('.menu-item-has-megamenu');
            allMainItems.each(function () {
                let megaMenu = $(this).find('.mega-menu');
                let megaMenuSubItems = megaMenu.find('.mega-menu__sub-items');
                megaMenu.css("visibility", "hidden");
                megaMenu.css("display", "block");
                megaMenuSubItems.css("visibility", "hidden");
                megaMenuSubItems.css("display", "block");
            });
        }

        const adapt = (megaMenu, megaMenuInside, megaMenuParent) => {

            if ($(window).width() > 1023) {

                const positionLeft = megaMenuParent.position().left - 15;

                //check if megaMenuInside has class columns
                if (!megaMenu.hasClass('columns')) {
                    megaMenuInside.css('margin-left', positionLeft + 'px');
                }

            }

            if (firstRun || resize) {
                let megaMenuSubItems = megaMenu.find('.mega-menu__sub-items');
                megaMenuSubItems.css("visibility", "visible");
                megaMenuSubItems.css("display", "none");
                megaMenu.css("display", "none");
                megaMenu.css("visibility", "visible");
            }
        }

        let allMainItems = this.element.find('.menu-item-has-megamenu');
        allMainItems.each(function () {
            let megaMenu = $(this).find('.mega-menu');
            let megaMenuInside = $(this).find('.mega-menu .inside');
            adapt(megaMenu, megaMenuInside, $(this));
        });

    }

    checkScroll() {
        if ( !$('div').hasClass('webinar_lp') ) {
            
            if ($(document).scrollTop() > 50) {
                this.element.addClass('fixed');
            } else {
                this.element.removeClass('fixed');
            }
            
        }
    }

    setupMegaMenu() {

        let self = this;
        let allMainItems = this.element.find('.menu-item-has-megamenu');

        self.adjustMegamenuPosition(false, true);

        $(window).on('resize', function () {
            self.adjustMegamenuPosition(true);
        });


        this.element.find('.menu-item').on('mouseenter', function () {
            self.element.find('.menu-item').removeClass('active');
            self.element.find('.mega-menu').hide();

            //Remove active class from all main items and add active to the one we are hovering
            self.element.find('.mega-menu__main-items__inside__items').removeClass('active');
            self.element.find('.mega-menu__sub-items__inside__items').removeClass('active');

            //Hide sub menu
            self.element.find('.mega-menu__sub-items').hide();
        });


        allMainItems.each(function () {
            let megaMenu = $(this).find('.mega-menu');
            let mainMenuItem = $(this);

            megaMenu.each(function () {
                let megaMenu = $(this);
                megaMenu.find('.mega-menu__main-items__inside__items').each(function () {

                    let megaMenuItem = $(this);
                    let megaMenuLink = megaMenuItem.find('a');
                    let megaMenuLinkId = megaMenuLink.data('id');

                    megaMenuItem.on('mouseenter', function () {

                        //Remove active class from all main items and add active to the one we are hovering
                        megaMenu.find('.mega-menu__main-items__inside__items').removeClass('active');
                        $(this).addClass('active');

                        if (megaMenuItem.hasClass('has-sub-menu')) {

                            //Show sub menu
                            megaMenu.find('.mega-menu__sub-items').show();

                            megaMenu.find('.mega-menu__sub-items__inside__items').removeClass('active');

                            let megaMenuSubItem = megaMenu.find('.mega-menu__sub-items__inside__items[data-id="' + megaMenuLinkId + '"]');
                            megaMenuSubItem.addClass('active');

                        } else {

                            //hide sub menu
                            megaMenu.find('.mega-menu__sub-items').hide();

                        }

                    });

                });
            });

            mainMenuItem.on('mouseenter', function () {
                //Remove active class from all main items and add active to the one we are hovering
                allMainItems.removeClass('active');
                allMainItems.find('.mega-menu').hide();

                //Show megamenu
                $(this).addClass('active');
                $(this).find('.mega-menu').show();
            });

            megaMenu.on('mouseleave', function () {

                //Remove active class from all main items and add active to the one we are hovering
                megaMenu.find('.mega-menu__main-items__inside__items').removeClass('active');
                megaMenu.find('.mega-menu__sub-items__inside__items').removeClass('active');

                //Hide sub menu
                megaMenu.find('.mega-menu__sub-items').hide();

                //hide megamenu
                megaMenu.hide();

                //Remove active class from all main items
                mainMenuItem.removeClass('active');

            });

            $(document).on('click', function (event) {
                if (!$(event.target).closest('.menu-item-has-megamenu').length) {
                    mainMenuItem.removeClass('active');
                    mainMenuItem.find('.mega-menu').hide();
                }
            });


        });


    }

    init() {

        this.checkScroll();
        this.setupMegaMenu();
        let self = this;

        $(window).on('scroll', function () {
            self.checkScroll();
        });
    }
}

const header = {
    init() {
        const headerElements = $('.header');

        if (null !== headerElements && headerElements.length > 0) {
            headerElements.each((index, element) => {
                new HeaderJs($(element));
            });
        }
    }
};

export default header;
import { $ } from '../vendors/jquery';

class MobileMenu
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      $( '.mobile-menu' ).click(function() {
        $( '.mobile-menu svg' ).toggleClass('active');
        $( '.mobile-menu-wrapper' ).toggleClass('d-none');
      });
    }
}

const mobilemenujs = {
    init() {
        const exampleElements = $( '.mobile-menu' );

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new MobileMenu( $( element ) );
            } );
        }
    }
};

export default mobilemenujs;

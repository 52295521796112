import { $ } from '../vendors/jquery';

const VideoModal = {

    init() {
        console.log('VideoModal init');

        $(document).ready(function () {
            $(".js-modal-btn").modalVideo({
                autoplay: true
            });
        });

    }
};

export default VideoModal;

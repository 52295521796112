import {isMobileViewport} from '../vendors/utilities.js';

class AccordionTabs {
    constructor(element) {
        this.element = element;
        this.blocks = this.element.querySelectorAll('[data-element="accordion-block"]');
        this.buttons = this.element.querySelectorAll('[data-element="accordion-button"]');
        this.contents = this.element.querySelectorAll('[data-element="accordion-content"]');
        this.firstBlock = this.blocks[0];
        this.firstContent = this.contents[0];
        this.lastBlock = this.blocks[this.blocks.length - 1];
        this.lastContent = this.contents[this.contents.length - 1];
    }

    init() {
        this.setFirstBlockOpen();
        this.blocks.forEach((block) => {
            block.addEventListener('keydown', this.onKeydown.bind(this));
        });
        this.buttons.forEach((button) => {
            button.addEventListener('click', this.onButtonClick.bind(this, button));
        });
    }

    onButtonClick(button) {
        this.closeAllBlocks();
        const block = button.closest('[data-element="accordion-block"]');
        block.classList.add('accordion__block--open');
        block.setAttribute('aria-expanded', true);
        block.setAttribute('aria-selected', true);
        block.setAttribute('tabindex', 0);
        this.element.querySelector('[data-element="accordion-content"][data-index="' + button.dataset.index + '"]').classList.add('accordion__content--show');

        if (!isMobileViewport()) {
            let wpadminbar = (jQuery('#wpadminbar').height()) ? jQuery('#wpadminbar').height() : 0;
            let headerHeight = jQuery('.header').height();
            let scrollPaddingTop = 30 + wpadminbar + headerHeight + 'px';
            jQuery('html').css('scroll-padding-top', scrollPaddingTop);
            this.element.closest('.accordion').scrollIntoView();
        }
    }

    onKeydown(event) {
        const currentBlock = event.currentTarget;
        let flag = false;

        switch (event.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
            this.moveFocusToPreviousBlock(currentBlock);
            flag = true;
            break;

        case 'ArrowDown':
        case 'ArrowRight':
            this.moveFocusToNextBlock(currentBlock);
            flag = true;
            break;

        case 'Home':
            this.moveFocusToBlock(this.firstBlock);
            flag = true;
            break;

        case 'End':
            this.moveFocusToBlock(this.lastBlock);
            flag = true;
            break;

        default:
            break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    moveFocusToBlock(currentBlock) {
        currentBlock.querySelector('[data-element="accordion-button"]').focus();
    }

    moveFocusToPreviousBlock(currentBlock) {
        if (currentBlock === this.firstBlock) {
            this.moveFocusToBlock(this.lastBlock);
        } else {
            this.moveFocusToBlock(currentBlock.previousElementSibling);
        }
    }

    moveFocusToNextBlock(currentBlock) {
        if (currentBlock === this.lastBlock) {
        this.moveFocusToBlock(this.firstBlock);
        } else {
        this.moveFocusToBlock(currentBlock.nextElementSibling);
        }
    }

    setFirstBlockOpen() {
        this.firstBlock.classList.add('accordion__block--open');
        this.firstBlock.setAttribute('aria-expanded', true);
        this.firstBlock.setAttribute('aria-selected', true);
        this.firstBlock.setAttribute('tabindex', 0);
        this.firstContent.classList.add('accordion__content--show');
    }

    closeAllBlocks() {
        this.blocks.forEach((block) => {
            block.classList.remove('accordion__block--open');
            block.setAttribute('aria-expanded', false);
            block.setAttribute('aria-selected', false);
            block.setAttribute('tabindex', -1);
        });
        this.contents.forEach((content) => {
            content.classList.remove('accordion__content--show');
        });
    }
}

const accordiontabs = {
    init() {
        const accordionItems = document.querySelectorAll('[data-module="accordion-tabs"]');

        if (accordionItems && accordionItems.length > 0) {
            accordionItems.forEach((item) => {
                const accordionItem = new AccordionTabs(item);
                accordionItem.init();
            });
        }
    },
};

export default accordiontabs;

import { $ } from '../vendors/jquery';

class FilterSalesSupport
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
        this.element.on('change', function(e) {
            let salesSupportSelected = $(this).find('option:selected').val();
            $('.sales_support_locations .locations').removeClass('active');
            $('.sales_support_locations .locations.'+ salesSupportSelected).toggleClass('active');
        });
    }
}

const filtersalessupportjs = {
    init() {
        const exampleElements = $('#search_sales_support')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new FilterSalesSupport( $( element ) );
            } );
        }
    }
};

export default filtersalessupportjs;

import { $ } from '../vendors/jquery';

const Partners = {

    init() {

        $(document).ready(function () {
            //detect ?p query on url and scroll to div id "partners-list"
            if (window.location.href.indexOf("?p") > -1) {
                $('html, body').animate({
                    scrollTop: $("#partners-list").offset().top
                }, 1000);
            }
        });

    }
};

export default Partners;

import { $ } from '../vendors/jquery';

class JiraWidget
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      $('.jirasupport .buttons .button-open, .jirasupport .buttons .button-close').on('click', function() {
        $('.jirasupport .buttons .button-open, .jirasupport .buttons .button-close, .jirasupport .form, .jirasupport .form form').toggleClass('active');
      });

      $('#sendsupport').on('click', function (e) {
        //console.log('clicked');
        e.preventDefault(); // if the clicked element is a link

        grecaptcha.ready(function () {

          grecaptcha.execute('6LftYyshAAAAAAN7l0q5fmVJTrnnxCoHQJgNIqu8', { action: 'submit' }).then(function (token) {

            $.post(
              alifteam.ajax.url,
              {
                action: 'alif_recaptcha_validation',
                recaptcha_token: token
              },
              function (response) {
                console.log('recaptcha response: ' + response)
                if (response == false )
                  return;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'Jira Contact Form'
                });
          
                var data = {
                  action: 'jirawidgetsupport',
                  fname: $('.jirasupport input[name=fname]').val(),
                  lname: $('.jirasupport input[name=lname]').val(),
                  company: $('.jirasupport input[name=company]').val(),
                  email: $('.jirasupport input[name=email]').val(),
                  subject: $('.jirasupport input[name=subject]').val(),
                  message: $('.jirasupport textarea[name=message]').val(),
                  captcha: response,
                };
  
                $.post(
                  alifteam.ajax.url,
                  data,
                  function (subresponse) {
                    //console.log(subresponse);
                    if (subresponse == 1) {
                      $('.jirasupport .form form')[0].reset();
                      $('.jirasupport .form form').removeClass('active');
                      $('.jirasupport .form .sucess').addClass('active');
                    }
                  }
                );

              }
            );

          });
        });

      });
    }
}

const jirawidgetjs = {
    init() {
        const exampleElements = $('.jirasupport')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new JiraWidget( $( element ) );
            } );
        }
    }
};

export default jirawidgetjs;

import { $ } from '../vendors/jquery';

class TeamPopup
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
      this.element.click(function(e) {
        const popupClicked = $(this).data('memberspopup');
        $('.profile_extrainfo').removeClass('active');
        $('.teammembers_popup_wrapper').find('[data-memberspopup]').removeClass('active');

        if( $('.teammembers_popup_wrapper [data-memberspopup='+popupClicked+']').length ) {
          $('.teammembers_popup_wrapper').find('[data-memberspopup='+popupClicked+']').addClass('active');
        }
      });
      $('.profile_extrainfo .close').on('click', function() {
        $('.teammembers_popup_wrapper').find('[data-memberspopup]').removeClass('active');
      });

      $(document).on('mouseup', function (e) {
        if( $(e.target).closest(".profile_extrainfo.active .content").length === 0 ) {
          $('.teammembers_popup_wrapper').find('[data-memberspopup]').removeClass('active');
        }
      });
    }
}

const teampopupjs = {
    init() {
        const exampleElements = $('.team_members .member_wrapper')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new TeamPopup( $( element ) );
            } );
        }
    }
};

export default teampopupjs;

import { Collapse } from 'bootstrap';
import swiperjs from './modules/swiper';
import mobilemenujs from './modules/mobile-menu';
import animejs from './modules/anime';
//import owlcarouseljs from './modules/owlcarousel';
import masonryjs from './modules/masonry';
import solutionspopupjs from './modules/popup-solutions';
import teampopupjs from './modules/popup-team';
import familyfusionjs from './modules/popup-familyfusion';
import accordionjs from './modules/accordion';
import searchnavbarjs from './modules/search';
//import filterpartnersjs from './modules/filter-partners';
import filterdistributorjs from './modules/filter-distributor';
import filtersalessupportjs from './modules/filter-sales-support';
import layoutchangesjs from './modules/layout-changes';
import jirawidgetjs from './modules/jirawidget';
//import newsletterjs from './modules/newsletter';
//import inputrangejs from './modules/input-range';
import filterordernowjs from './modules/filter-order-now';
import Partners from './modules/partners';
import VideoModal from './modules/video-modal';
import header from './modules/header';
import BlogFilter from './modules/blog-filter';
import BlogLoadMore from './modules/blog-load-more';
import AccordionTabs from './modules/accordion-tabs';

(function ($) {
    'use strict';

    $(function () {
        BlogLoadMore.init();
        layoutchangesjs.init();
        swiperjs.init();
        mobilemenujs.init();
        animejs.init();
        //owlcarouseljs.init();
        masonryjs.init();
        solutionspopupjs.init();
        teampopupjs.init();
        familyfusionjs.init();
        accordionjs.init();
        accordionjs.locationsInit();
        searchnavbarjs.init();
        //filterpartnersjs.init();
        filterdistributorjs.init();
        filtersalessupportjs.init();
        jirawidgetjs.init();
        //newsletterjs.init();
        //inputrangejs.init();
        filterordernowjs.init();
        Partners.init();
        VideoModal.init();
        header.init();
        BlogFilter.init();
        //BlogAutocompleteJS.init();
        AccordionTabs.init();

        $('.counter .value').each(function () {
            $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
            }, {
                duration: 3500,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });

        window.registrationFormSubmit = function () {
            document.getElementById('registrationform').submit();
        };

    });

})(jQuery);

import { $ } from '../vendors/jquery';

class FilterDistributor
{
    constructor( element )
    {
        this.element = element;
        this.init();
    }

    init()
    {
        this.element.on('change', function(e) {
            let distributorSelected = $(this).find('option:selected').val();
            $('.distributor_results .distributor').removeClass('active');
            $('.distributor_results').find('.' + distributorSelected).toggleClass('active');
        });
    }
}

const filterdistributorjs = {
    init() {
        const exampleElements = $('#search_distributor')

        if ( null !== exampleElements && exampleElements.length > 0 ) {
            exampleElements.each( ( index, element ) => {
                new FilterDistributor( $( element ) );
            } );
        }
    }
};

export default filterdistributorjs;
